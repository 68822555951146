import React from 'react';
import ReactDOM from 'react-dom';
import { matchPath } from 'react-router-dom';
import type { History } from 'history';

import { SessionStore } from '@pushwoosh/session-store';
import type { HttpClient, RpcClient, GrpcClient } from '@pushwoosh/http-client';
import type { GrpcBridge } from '@pushwoosh/grpc-bridge';

import { Root } from '~/src/root.component';
import {
  checkOnboardingNeeded,
  getProductIcons,
  getProductName,
  loadExternalScripts,
} from './helpers/app.helpers';

type StartMicroFrontendOptions = {
  history: History;
  httpClient: HttpClient;
  rpcClient: RpcClient;
  grpcClient: GrpcClient;
  grpcBridge: GrpcBridge;
  billingGrpcClient: GrpcClient;
  journeyGrpcClient: GrpcClient;
};

export async function startMicroFrontend(options: StartMicroFrontendOptions): Promise<void> {
  const {
    history,
    httpClient,
    rpcClient,
    grpcClient,
    grpcBridge,
    billingGrpcClient,
    journeyGrpcClient,
  } = options;

  // create an instance of SessionStore to manage session data
  const sessionStore = new SessionStore(grpcClient);
  // load the current session data
  await sessionStore.update();

  // check if the account is new and redirect to plan selection if it is
  const res = await checkOnboardingNeeded(grpcClient);

  const journeyRoutes = ['/journeys/:applicationCode/list', '/journeys/list'];
  const journeysMatch = !!matchPath(window.location.pathname, { path: journeyRoutes });

  if (res.getIsActive()) {
    if (res.getPaywallPlan()) {
      history.push('/subscription-select');
    } else if (res.getWelcomeScreen() && !journeysMatch) {
      history.push('/welcome-screen');
    }
  }

  const htmlCollectionTitle = document.getElementsByTagName('title');
  const [title] = Array.from(htmlCollectionTitle);
  if (title && title.innerText) {
    title.innerText = getProductName();
  }

  const htmlCollectionHead = document.getElementsByTagName('head');
  const [head] = Array.from(htmlCollectionHead);
  if (head && head.innerText) {
    head.innerHTML += getProductIcons();
  }

  try {
    ReactDOM.render(
      (
        <Root
          history={history}
          httpClient={httpClient}
          rpcClient={rpcClient}
          grpcClient={grpcClient}
          grpcBridge={grpcBridge}
          billingGrpcClient={billingGrpcClient}
          journeyGrpcClient={journeyGrpcClient}
          sessionStore={sessionStore}
          isWelcomeScreenActive={res.getWelcomeScreen()}
          isGetStartedActive={res.getGetStarted()}
        />
      ),
      document.getElementById('root'),
      loadExternalScripts,
    );

    document.body.classList.add('is-loaded');
  } catch (error) {
    console.error('index.app', error);
    document.body.classList.add('is-crashed');
  }
}
