import { RpcServiceClientInstances, rpcServiceClient } from '@pushwoosh/grpc-bridge';

import type { GrpcClient } from '@pushwoosh/http-client';
import type { Session } from '@pushwoosh/session-store';

function loadScript(id: string, src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');

      script.onload = (): void => {
        resolve();
      };
      script.onerror = (): void => {
        reject();
      };

      script.src = src;
      script.id = id;
      script.async = true;

      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
}

export function loadExternalScripts(): void {
  // Load 3rd party libraries and scripts
  const isOnJourney = window.location.pathname.startsWith('/journeys');
  const isOnPushwoosh = window.location.hostname.includes('pushwoosh.com');
  if (isOnPushwoosh) {
    loadScript('ze-snippet', 'https://static.zdassets.com/ekr/snippet.js?key=f7ccd1c3-4384-4d19-9ce2-e79a04a11170')
      .then(() => isOnJourney && window.zE('webWidget', 'hide'));
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  loadScript('gtm', 'https://www.googletagmanager.com/gtm.js?id=GTM-MZSQXFB');

  loadScript('louAssist', '//run.louassist.com/v2.5.1-m?id=121647625417').then(() => {
    const louAssistEvent = new CustomEvent('louAssistLoaded');
    window.dispatchEvent(louAssistEvent);
  });
}

export function checkOnboardingNeeded(grpcClient: GrpcClient): Promise<RpcServiceClientInstances['accounts']['GetOnboardingInfoResponse']> {
  const req = new rpcServiceClient.accounts.GetOnboardingInfoRequest();
  const accountsGrpcClient = grpcClient.createServiceClient(rpcServiceClient.accounts.AccountsServiceClient);

  return accountsGrpcClient.getOnboardingInfo(req, null);
}

export function identifyUserInLOU(session: Session): void {
  const louAssist = window.LOU;
  const { user, account } = session;

  if (louAssist) {
    louAssist.identify(user.email, {
      productType: account.productType,
      companyName: account.billing.companyName,
      registrationDate: account.registrationDate,
      email: user.email,
    });
  }
}

export function setApplicationCode(pathname: string = ''): void {
  const applicationsRegex = /\/applications\/([A-Z0-9-]+)\//i;
  const applicationCode = pathname.match(applicationsRegex)?.[1] || '';
  const currentValue = localStorage.getItem('lastUsedApplication');

  if (applicationCode && currentValue !== applicationCode) {
    localStorage.setItem('lastUsedApplication', applicationCode);
  }
}

export function getProductName(): string {
  if (origin.includes('app.omo-tech')) {
    return 'OMO Grow Management Platform';
  }

  return 'Pushwoosh';
}

export function getProductIcons(): string {
  if (origin.includes('app.omo-tech')) {
    return `
      <link rel="shortcut icon" href="/static/favicon-omo.png">
      <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon-omo.png" />
      <link rel="mask-icon" href="/static/safari-pinned-tab-omo.svg" color="#0F4DBA" />
    `;
  }

  return `
    <link rel="apple-touch-icon" sizes="180x180" href="/static/apple-touch-icon-pw.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
    <link rel="manifest" href="/static/site.webmanifest" />
    <link rel="mask-icon" href="/static/safari-pinned-tab-pw.svg" color="#0077ff" />
  `;
}
