import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getUserActionsApiOrigin } from './auth.helpers';
import { trackMixpanelEvent } from './mixpanel';

interface Thresholds {
  [key: number]: boolean;
}

interface UTMParams {
  [key: string]: string;
}

interface MetricAttributes {
  ln: string;
  href?: string | null;
  acc_id?: number | null;
  app_id?: string;
  utm?: UTMParams;
  clickedText?: string | null;
  threshold?: number;
  n_user?: boolean;
}

interface MetricData {
  user_id?: string;
  action_type: string;
  attributes: MetricAttributes;
}

function getPage(): string {
  return `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
}

function extractAppId(url: string): string | null | undefined {
  const pattern = /\/(applications|journeys)\/([^/]+)/;
  const match = url.match(pattern);

  return match ? match[2] : null;
}

function sendMetrics(data: MetricData): void {
  const urlWithoutParams = getPage();
  const appId = extractAppId(window.location.href);

  const fullData = {
    page: urlWithoutParams,
    ...data,
    attributes: JSON.stringify({ ...data.attributes, ...(appId && { app_id: appId }) }),
  };

  trackMixpanelEvent({
    name: data.action_type,
    properties: {
      page: urlWithoutParams,
      user_id: data.user_id,
      ln: data.attributes.ln,
      utm: data.attributes.utm,
      href: data.attributes.href,
      acc_id: data.attributes.acc_id,
      app_id: data.attributes.app_id,
      clickedText: data.attributes.clickedText,
      threshold: data.attributes.threshold,
      n_user: data.attributes.n_user,
    },
  });

  navigator.sendBeacon(`${getUserActionsApiOrigin()}/api/v1/statistics/user-action/collect`, JSON.stringify(fullData));
}

function getUTMParams(queryString: string = window.location.search): UTMParams {
  const query = queryString.startsWith('?') ? queryString.substring(1) : queryString;
  const utmParams: UTMParams = {};

  query.split('&').forEach((pair) => {
    const [key, value] = pair.split('=');
    if (key?.startsWith('utm_') && value) {
      utmParams[key] = decodeURIComponent(value.replace(/\+/g, ' '));
    }
  });

  return utmParams;
}

function getClickableParentText(element: HTMLElement | null): { isClickableElement: boolean, clickedText: string | null } {
  let curElement = element;

  while (curElement && curElement !== document.body) {
    if (curElement.onclick && typeof curElement.onclick === 'function') {
      return { isClickableElement: true, clickedText: curElement.textContent };
    }

    curElement = curElement.parentElement;
  }

  return { isClickableElement: false, clickedText: null };
}

export class UserMetricsCollector {
  private ln: string;

  private user_id?: string;

  private acc_id?: number;

  private thresholds: Thresholds;

  constructor() {
    this.ln = navigator.language;
    this.thresholds = {
      25: false, 50: false, 75: false, 100: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  public onRouteChange(): void {
    this.user_id = Cookies.get('user_id');
    const isFirstTimeVisit = !this.user_id;

    if (!this.user_id) {
      this.user_id = uuidv4();
      Cookies.set('user_id', this.user_id, {
        domain: '.pushwoosh.com',
        path: '/',
      });
    }

    const utmParams = getUTMParams();
    sendMetrics({
      user_id: this.user_id,
      action_type: 'PAGE_VISIT',
      attributes: {
        ln: this.ln, ...(Object.keys(utmParams).length && { utm: utmParams }), acc_id: this.acc_id, ...(isFirstTimeVisit && { n_user: isFirstTimeVisit }),
      },
    });
  }

  public onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    const link = target.closest('a');
    const button = target.closest('button');

    const isButtonClicked = target.tagName === 'BUTTON' || button !== null;
    const isLinkClicked = link !== null;
    const actionType: string = 'CLICK';

    if (isButtonClicked || isLinkClicked) {
      const href = isLinkClicked ? link.href : null;

      sendMetrics({
        user_id: this.user_id,
        action_type: actionType,
        attributes: {
          ln: this.ln, ...(isLinkClicked && { href }), clickedText: target.textContent, acc_id: this.acc_id,
        },
      });
    } else {
      const { isClickableElement, clickedText } = getClickableParentText(target);
      if (isClickableElement) {
        sendMetrics({
          user_id: this.user_id,
          action_type: actionType,
          attributes: {
            ln: this.ln, clickedText, acc_id: this.acc_id,
          },
        });
      }
    }
  }

  public onScroll(): void {
    const scrolledPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;

    Object.keys(this.thresholds).forEach((key) => {
      const threshold = Number(key);
      if (scrolledPercentage >= threshold && !this.thresholds[threshold]) {
        sendMetrics({ user_id: this.user_id, action_type: 'SCROLL', attributes: { ln: this.ln, threshold, acc_id: this.acc_id } });

        this.thresholds[threshold] = true;
      }
    });
  }

  public setAccountId(accountId?: number): void {
    this.acc_id = accountId;
  }

  public subscribe(): void {
    window.addEventListener('click', this.onClick);
    window.addEventListener('scroll', this.onScroll);
    this.onRouteChange();
  }

  public unsubscribe(): void {
    window.removeEventListener('click', this.onClick);
    window.removeEventListener('scroll', this.onScroll);
  }
}
